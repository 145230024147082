// MyFunctionalComponent.tsx
import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './BookingWidget.module.scss'; // Replace with your actual CSS module import
import {ReactComponent as CalendarIcon} from "../../../assets/images/svg/CalendarIconWhite.svg";
import {ReactComponent as ClockIcon} from "../../../assets/images/svg/ClockIconWhite.svg";
import {ReactComponent as InfoIcon} from "../../../assets/images/svg/InfoIcon.svg";
import {ReactComponent as CloseIcon} from '../../../assets/images/svg/CloseIconOutline.svg';


import {useNavigate} from "react-router-dom";
import Calendar from "../SearchComponent/Calendar.component";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {scheduler} from "timers/promises";
import {start} from "repl";
import socket from "../../../socket";

interface Slot {
    start: number;
    end: number;
    date: Date;
    amOrPm?: string;
}

interface RoomProps {
    date: string;
    multiple?: boolean;
    preselected?: any[];
    mode?: string;
    buttonText?: string;
    hours: number;
    callback: (selectedHours: Slot[], amount: any) => void;
    room: {
        bookedSlots: { start: number; end: number; date: string }[];
        price: string;
        priceSuffix: string;
        oldPrice: string;
        minHours: number;
        defaultHours?: number;
        _id: string;
        schedule?: any;
    };
}

const MyFunctionalComponent: React.FC<RoomProps> = ({ date, hours, callback, room , buttonText='Book', multiple=true, mode='new', preselected=[]}) => {
    const user = useSelector((state: RootState)=>state.auth.userFull)
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectedHours, setSelectedHours] = useState<Slot[]>(preselected && preselected.length>0 ? [{...preselected[0], date: new Date(preselected[0].date)}] : []);
    const [dates, setDates] = useState<{ formatted: string; clear: Date; dw: string }[]>([]);
    const [showGradient, setShowGradient] = useState(true);
    const navigation = useNavigate()
    const [timeIntervals, setTimeIntervals] = useState<string[]>([
        '01 am - 02 am',
        '02 am - 03 am',
        '03 am - 04 am',
        '04 am - 05 am',
        '05 am - 06 am',
        '06 am - 07 am',
        '07 am - 08 am',
        '08 am - 09 am',
        '09 am - 10 am',
        '10 am - 11 am',
        '11 am - 12 pm',
        '12 pm - 01 pm',
        '01 pm - 02 pm',
        '02 pm - 03 pm',
        '03 pm - 04 pm',
        '04 pm - 05 pm',
        '05 pm - 06 pm',
        '06 pm - 07 pm',
        '07 pm - 08 pm',
        '08 pm - 09 pm',
        '09 pm - 10 pm',
        '10 pm - 11 pm',
        '11 pm - 12 am'
    ]);
    const [selectedInterval, setSelectedInterval] = useState<string>('1');
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [currentDate, setCurrentDate] = useState<any>(null)
    const [openCalendarInfo, setOpenCalendarInfo] = useState<any>(null)
    const [openHoursInfo, setOpenHoursInfo] = useState<any>(null)
    const daysMap = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [tmpBusySlots, setTmpBusySlots] = useState<any[]>([])
    const { bookedSlots, price } = room;
    const hoursTableRef = useRef<HTMLDivElement>(null);
    const [currentSocketId, setCurrentSocketId] = useState<any>(null)
    const [countdown, setCountdown] = useState(300); // 10 minutes in seconds
    useEffect(() => {


        socket.on('connect', () => {
            setCurrentSocketId(socket.id);
        });

        socket.on('initial', (msg) => {
            if (msg && msg.slots.hasOwnProperty(room._id)) {
                setTmpBusySlots(msg.slots[room._id])
            }
        });

        socket.on('disconnect', () => {
        });

        socket.on('slot selected', (booking) => {
            if (booking && booking.hasOwnProperty(room._id)) {
                setTmpBusySlots(booking[room._id])
            }
        });

        socket.on('slot deselected', (booking) => {
            if (booking && booking.hasOwnProperty(room._id)) {
                setTmpBusySlots(booking[room._id])
            }
        });

        socket.on('update slots', (booking) => {
            if (booking && booking.hasOwnProperty(room._id)) {
                setTmpBusySlots(booking[room._id])
            }
        });
        socket.on('updateId', (msg) => {
            if (msg && msg.slots && msg.slots.hasOwnProperty(room._id) && msg.id && msg.id===socket.id) {
                setTmpBusySlots(msg.slots[room._id])
            }
        });
        socket.emit('update')

        return () => {
            if (socket) {
                //socket.disconnect(); // Отключаемся от сервера при размонтировании компонента
            }
        };
    }, []);
    useEffect(() => {
        if (hours) {
            setSelectedInterval(hours.toString())
        }
        const interval = setInterval(() => {
            setCountdown(prevCountdown => { //тест
                if (prevCountdown > 0) {
                    return prevCountdown - 1;
                } else {
                    clearInterval(interval);
                    window.location.href = '/rooms/'+room._id; // Redirect to the booking page after the countdown
                    return 0;
                }
            });
        }, 1000); // Update countdown every second
        return () => clearInterval(interval); // Cleanup: clear interval on unmount
    }, []);

    // Format countdown in minutes and seconds
    const minutes = Math.floor(countdown / 60).toString().padStart(2, '0');
    const seconds = (countdown % 60).toString().padStart(2, '0');


    const inSelected = (timeInterval: string, date: Date): string => {
        const timeRegex = /^(\d+)\s+-\s+(\d+)$/i;
        const match = timeInterval.match(timeRegex);

        if (!match) {
            return ''; // Некорректный формат времени
        }

        const [, startHourStr, endHourStr] = match;
        const startHour = parseInt(startHourStr, 10);
        const endHour = parseInt(endHourStr, 10);
        const dateStr = date.toISOString().slice(0, 10); // Для стандартизации сравнения дат

        const isInSelected = selectedHours.some(slot => {
            const slotDateStr = slot.date.toISOString().slice(0, 10);
            // Проверка пересечения интервалов
            const overlaps = (slot.start < endHour && slot.end > startHour) && slotDateStr === dateStr;
            if (overlaps) {
            }
            return overlaps;
        });

        if (!isInSelected) {
        }
        return isInSelected ? styles.selected : '';
    };



    useEffect(()=>{
        if (date) {
            setCurrentDate(date)
        } else {
            setCurrentDate(`${new Date().getDate()}.${(new Date().getMonth() + 1).toString().padStart(2, '0')}.${new Date().getFullYear()}`)
        }

    }, [])

    useEffect(() => {
        if (preselected && preselected.length > 0 && preselected[0].date) {
            const updatedPreselected = [...preselected];
            updatedPreselected[0] = { ...updatedPreselected[0], date: new Date(preselected[0].date) };
            setSelectedHours(updatedPreselected); // Обновите selectedHours с данными из preselected
        }
    }, [preselected]);

    useEffect(()=>{
        generateDates()
    }, [currentDate])

    useEffect(()=>{
        setSelectedHours((old)=>old.filter(x=> x.end - x.start === parseInt(selectedInterval)))
    }, [selectedHours.length])

    const selectSlot = (timeInterval: string, date: Date) => {
        if (!multiple) {
            setSelectedHours([])
            socket.emit('clear by id')
        }
        const timeRegex = /^(\d+)\s+-\s+(\d+)$/i;
        const [, startHourStr, endHourStr] = timeInterval.match(timeRegex) || [];
        if (!startHourStr || !endHourStr) {
            console.error('Неверный формат времени.');
            return;
        }

        const startHour = parseInt(startHourStr, 10);
        const endHour = parseInt(endHourStr, 10);
        const idx = selectedHours.findIndex(
            (slot) =>
                slot.start === startHour &&
                slot.end === endHour &&
                slot.date.toDateString() === date.toDateString()
        );
        if (idx > -1) {
            setSelectedHours((prevSelected) => prevSelected.filter((_, index) => index !== idx));
            socket.emit('slot deselected', {slotId: { start: startHour, end: endHour, date }, roomId: room._id})


        } else {
            setSelectedHours((prevSelected) => [...prevSelected, { start: startHour, end: endHour, date }]);
            socket.emit('slot selected', {slotId: { start: startHour, end: endHour, date }, roomId: room._id})

        }
    };


    const openSelect = () => {
        if (mode==='edit') {
            return
        }
        setSelectOpen(true);
    };

    const toggleCalendar = () => {
        setShowCalendar(old=>!old)
    }


    const generateDates = () => {
        // Generate an array of dates for the next five days
        let today = new Date();
        if (currentDate) {
            const [day, month, year] = currentDate.split('.').map(Number);

            const date = new Date(Date.UTC(year, month - 1, day));
            today = date;
        }
        const options = { day: 'numeric', month: 'short' };
        const datesArray = [];
        for (let i = 0; i < 5; i++) {
            const date = new Date(today.getTime() + i * 24 * 60 * 60 * 1000);
            // @ts-ignore
            const formattedDate = date.toLocaleDateString(undefined, options);
            datesArray.push({ formatted: formattedDate, clear: date, dw: daysMap[date.getDay()] });
        }
        setDates(datesArray);
    };


    const generateTimeIntervals = () => {
        // Clear previous time intervals and generate new ones based on the selected interval
        setTimeIntervals((prevTimeIntervals) => {
            const intervals = [];
            const selectedIntervalHours = parseInt(selectedInterval, 10);
            const startTime = 8;
            const endTime = 23;


            for (let hour = startTime; hour < endTime; hour += selectedIntervalHours) {
                const startHour = hour;
                let endHour = (hour + selectedIntervalHours) % 24;
                const formattedStartHour = startHour.toString().padStart(2, '0');
                const formattedEndHour = endHour.toString().padStart(2, '0');
                const timeInterval = `${formattedStartHour} - ${formattedEndHour}`;
                if (endHour===0) {
                    endHour = 24
                }
                if (endHour <= 23) {
                    intervals.push(timeInterval);
                }
            }
            return intervals;
        });
    };

    function isDateBeforeToday(date: any) {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set time to midnight
        date.setHours(0, 0, 0, 0); // Set time to midnight
        return date < currentDate;
    }

    function isDateTomorrow(date: any) {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set time to midnight
        date.setHours(0, 0, 0, 0); // Set time to midnight
        return date > currentDate;
    }



    const isBusyInterval = (timeInterval: string, date: Date) => {
        const timeRegex = /^(\d+)\s+-\s+(\d+)$/i;
        const [, startHourStr, endHourStr] = timeInterval.match(timeRegex) || [];
        if (!startHourStr || !endHourStr) {
            console.error('Неверный формат времени.');
            return true; // Return true to indicate an error since the time interval format is invalid.
        }
        if (room && room._id && (room._id === '64cd14e3b8821d6cf7d5e782' )) { //rooms filter
            return true
        }
        const startHour = parseInt(startHourStr, 10);
        let endHour = parseInt(endHourStr, 10);

        if (endHour === 0) { // еуые
            endHour = 24;
        }

        const busySlots = [
            ...bookedSlots.filter((slot) => new Date(slot.date).toLocaleDateString() === date.toLocaleDateString()),
            ...tmpBusySlots.filter((slot) => new Date(slot.date).toLocaleDateString() === date.toLocaleDateString() && slot.client !== socket.id)
        ];

        if (isDateBeforeToday(new Date(date))) {
            return true;
        }

        if (startHour < new Date().getHours() && !isDateTomorrow(new Date(date))) {
            return true;
        }
        // if (room && room.hasOwnProperty('schedule')) {
        //     if (room.schedule.hasOwnProperty(selectedInterval)) {
        //         if (room.schedule[selectedInterval].limitations && room.schedule[selectedInterval].limitations.days) {
        //             if (room.schedule[selectedInterval].limitations.days.length===0 || room.schedule[selectedInterval].limitations.days.includes(new Date().getDay())) {
        //                 if (startHour < room.schedule[selectedInterval].limitations.start || endHour > room.schedule[selectedInterval].limitations.end) {
        //                     return true
        //                 }
        //             }
        //         }
        //     }
        // }
//
        for (const slot of busySlots) {
            const slotStartHour = slot.start;
            const slotEndHour = slot.end;
            if (date.toLocaleDateString() === new Date(slot.date).toLocaleDateString()) {
                if (
                    (startHour >= slotStartHour && startHour < slotEndHour) ||
                    (endHour > slotStartHour && endHour <= slotEndHour) ||
                    (slotStartHour >= startHour && slotStartHour < endHour) ||
                    (slotEndHour > startHour && slotEndHour <= endHour)
                ) {
                    return true;
                }
            }
        }

        return false;
    };

    useEffect(() => {
    }, [selectedHours]);
    const isBusyTime = (timeInterval: string, date: Date): boolean => {
        // Check if the provided time interval overlaps with any busy time slot
        // const [startHour, endHour] = timeInterval.split('-').map((hour) => parseInt(hour));
        // const busySlots = bookedSlots.filter((slot) => new Date(slot.date).toDateString() === date.toDateString());
        // for (const slot of busySlots) {
        //     if (
        //         (startHour <= endHour && startHour <= slot.end && endHour >= slot.start) ||
        //         (startHour > endHour && (startHour <= slot.end || endHour >= slot.start))
        //     ) {
        //         return true;
        //     }
        // }
        return false;
    };

    useEffect(() => {
        generateDates();
        generateTimeIntervals();
    }, [selectedInterval]);

    const handleScroll = (event: any) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;
        setShowGradient(!isScrolledToBottom);
    };

    useEffect(() => {
        const hoursTableContainer = hoursTableRef.current;
        if (hoursTableContainer) {
            if (hoursTableContainer.scrollHeight > 100 && !showGradient) {
                hoursTableContainer.classList.add(styles['scroll-fade']);
            } else {
                hoursTableContainer.classList.remove(styles['scrollable-container']);
            }
        }
    }, [dates, timeIntervals, selectedHours, showGradient, preselected]);


    const hoursCount = selectedHours.reduce((totalHours, interval) => {
        const startHour = interval.start;
        const endHour = interval.end === 0 ? 24 : interval.end;
        const hourDiff = endHour >= startHour ? endHour - startHour : 24 - startHour + endHour;
        return totalHours + hourDiff;
    }, 0);

    useEffect(()=>{
        if (!preselected) {
            setSelectedHours([])
            socket.emit('clear by id')
        }
        if (room && room.minHours) {
            // setSelectedInterval(room.minHours.toString())
            // if (room.defaultHours && room.defaultHours > room.minHours) {
            //     setSelectedInterval(room.defaultHours.toString())
            // }
        }
    }, [room])


    const mergeTimeSlots = (slots: any[]) => {
        const sortedSlots = slots.sort((a, b) => {
            if (a.date < b.date) {
                return -1;
            }
            if (a.date > b.date) {
                return 1;
            }
            if (a.start < b.start) {
                return -1;
            }
            if (a.start > b.start) {
                return 1;
            }
            return 0;
        });

        const mergedSlots = [];
        let currentSlot = sortedSlots[0];

        for (let i = 1; i < sortedSlots.length; i++) {
            const nextSlot = sortedSlots[i];

            // Check if the currentSlot and nextSlot are adjacent and have the same date.
            if (areAdjacent(currentSlot, nextSlot) && currentSlot.date === nextSlot.date) {
                currentSlot.end = nextSlot.end; // Extend the currentSlot's end time.
            } else {
                mergedSlots.push({ ...currentSlot }); // Push a copy of the currentSlot to the mergedSlots array.
                currentSlot = { ...nextSlot }; // Make a copy of the nextSlot to start a new merged slot.
            }
        }

        mergedSlots.push({ ...currentSlot }); // Push the last merged slot.
        return mergedSlots;
    };

    const areAdjacent = (slot1: { end: any; }, slot2: { start: any; }) => {
        return slot1.end === slot2.start; // Check if the end time of slot1 is equal to the start time of slot2.
    };

//

    function bookSlots() {
        // @ts-ignore
        let amount:any = (parseFloat(room.price) * parseInt(selectedInterval, 10) *selectedHours.length.toFixed(2))
        if (user && user.discount) {
            amount = Math.floor((amount - amount * user.discount/100 )*100)/100
        }
         callback(mergeTimeSlots(selectedHours),amount)
    }

    return (
        <div className={styles['select-book-time-window']}>
            {(selectOpen || showCalendar) && (
                <div style={{zIndex: 10, position: "absolute", background: 'rgba(22, 26, 32, 0.50)', top: 0, bottom: 0, left: 0, right: 0}} onClick={()=>{
                    setShowCalendar(false)
                    setSelectOpen(false)
                }}/>
            )}
            <div className={'row justify-content-between mb-20'}>
                <div className={'row gap-20 align-items-center'}>
                    {(!user || !user.discount || user.discount === 0) ? (
                        <span className={'text-price'}>
      {room.price} {room.priceSuffix}
    </span>
                    ) : (
                        <>
      <span className={'text-price'}>
        {Math.floor((parseFloat(room.price) - parseFloat(room.price) * user.discount / 100) * 100) / 100} {room.priceSuffix}
      </span>
                            <span className={'old-price crossline'}>{room.price} {room.priceSuffix}</span>
                        </>
                    )}
                </div>

                <span className={'blue-text pointer'} onClick={()=>navigation('/spots')}>More rooms</span>
            </div>
            <div className={`${styles['select-time-select']} row align-items-center pa-5 justify-content-between relative`}>
                <div className={`col-12 row align-items-center gap-20 pointer justify-content-between pr-10`}>
                    <div className={'row align-items-center  gap-10 '} onClick={toggleCalendar}>
                        <CalendarIcon/>
                        <p>{currentDate}</p>
                    </div>
                    {!openCalendarInfo && <InfoIcon onClick={() => setOpenCalendarInfo((old: any) => !old)}/>}
                    {openCalendarInfo && (<CloseIcon onClick={() => setOpenCalendarInfo((old: any) => !old)} />)}
                    {openCalendarInfo && (
                        <div className={styles.popup} style={{zIndex: 111, right: 255}}>
                            <span className={'text-black main-text text-align-center'}>Select date</span>
                        </div>
                    )}
                    {showCalendar && (
                        <Calendar selected={Number(date.split('.')[0])} dark={true} handleCancelButton={toggleCalendar} handleDateSelect={(date)=> {
                            setCurrentDate(date)
                            toggleCalendar()
                        }} />
                    )}
                </div>
                <div className={`${styles['v-divider']}`} />
                <div className={`col-12 ml-5 row align-items-center gap-20 justify-content-between relative`} style={{zIndex: 888}} >
                    <div className={`relative row align-items-center gap-20 pointer justify-content-between col-24 pr-10`} >
                        <div className={'row align-items-center  gap-10 '} onClick={openSelect}>
                            <ClockIcon />
                            <p>{selectedInterval} hour</p>
                        </div>
                        {!openHoursInfo && <InfoIcon onClick={() => setOpenHoursInfo((old: any) => !old)}/>}
                        {openHoursInfo && <CloseIcon onClick={() => setOpenHoursInfo((old: any) => !old)}/>}
                        {openHoursInfo && (
                            <div className={styles.popup} style={{zIndex: 111}}>
                                <span className={'text-black main-text text-align-center'}>Select number of hours</span>
                            </div>
                        )}
                    </div>

                    {/*<ChevronDoun className={`${styles['pointer']}`} />*/}
                </div>
                {selectOpen && (
                    <div className={`col-12 col justify-content-center gap-20 ${styles['hours-select']} `} ref={hoursTableRef}>
                        {(!room.minHours || room.minHours <  2) && (
                            <span className={`pointer ${styles['list-item']}`} onClick={() => { setSelectedInterval('1'); setSelectOpen(false); setSelectedHours([]); socket?.emit('clear by id')}}>1 hour</span>
                        )}
                        {room && room.minHours && room.minHours < 3 && (
                            <>
                                <span className={`pointer ${styles['list-item']}`} onClick={() => { setSelectedInterval('2'); setSelectOpen(false); setSelectedHours([]); socket?.emit('clear by id') }}>2 hours</span>
                                <span className={`pointer ${styles['list-item']}`} onClick={() => { setSelectedInterval('3'); setSelectOpen(false); setSelectedHours([]); socket?.emit('clear by id')}}>3 hours</span>
                            </>
                        )}

                        {room && room.minHours && room.minHours === 3 && (
                            <span className={`pointer ${styles['list-item']}`} onClick={() => { setSelectedInterval('3'); setSelectOpen(false); setSelectedHours([]); socket?.emit('clear by id')}}>3 hours</span>
                        )}
                    </div>
                )}
            </div>
            <div className={styles['scrollable-container']} onScroll={handleScroll}>
                <div className={`row justify-content-start`} style={{position: 'sticky', top: 0, zIndex: 2, background: '#343643'}}>
                    <div className={styles['border']}  style={{maxWidth: '100px', minWidth: '80px'}} />
                    {dates.map((dateItem) => (
                        <div className={styles['border']} key={dateItem.formatted} style={{maxWidth: '100px', minWidth: '75px'}}>
                            <p>{dateItem.formatted}</p>
                            <p className={'text-10'}>{dateItem.dw}</p>
                        </div>
                    ))}
                </div>
                {timeIntervals.map((timeInterval) => (
                    <div className={`row`} key={timeInterval}>
                        <div className={styles['border']}  style={{maxWidth: '100px', minWidth: '80px'}}>
                            <p>{timeInterval}</p>
                        </div>
                        {dates.map((dateItem) => (
                            <div
                                className={`row align-items-center justify-content-center  ${styles['border-body']} ${inSelected(timeInterval, dateItem.clear)}`}
                                key={dateItem.formatted}
                                style={{ maxWidth: '100px', minWidth: '75px', background: isBusyInterval(timeInterval, dateItem.clear) ? '#252730' : '' }}
                                onClick={() => {
                                    if (!isBusyInterval(timeInterval, dateItem.clear) && !isBusyTime(timeInterval, dateItem.clear)) {
                                        selectSlot(timeInterval, dateItem.clear)
                                    }
                                }}
                            >
                                {isBusyInterval(timeInterval, dateItem.clear) ? <div /> : isBusyTime(timeInterval, dateItem.clear) ? <div className={'row align-items-center'} /> : <div className={`${styles['free']} pointer`}>€{Math.floor((parseFloat(room.price) * parseInt(selectedInterval, 10) - ((user && user.discount ? parseInt(user.discount) : 0) /100 * parseFloat(room.price) * parseInt(selectedInterval, 10)))*100)/100}</div>}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {selectedHours.length > 0 && (
                <div className={'col align-items-center'}>
                    {mode!=='edit' && (
                        <span className={'magenta-text spot-title'}>{`Total cost ${(Math.floor(((parseFloat(room.price) * parseInt(selectedInterval, 10) *selectedHours.length)-(parseFloat(room.price) * parseInt(selectedInterval, 10) *selectedHours.length * (user && user.discount ? parseInt(user.discount) : 0) /100))*100))/100}€ for ${selectedHours.length * parseInt(selectedInterval, 10)} hours`}</span>
                    )}
                    <div className={'red-button row align-content-center justify-content-center mt-10 pointer wrap'} onClick={bookSlots}>
                        <span className={'red-button-text'}>{buttonText}</span>
                    </div>
                </div>
            )}
            {/*<Button className={`mt-4`} text={`Select ${hoursCount} hour(s)`} callback={selectHours} />*/}
        </div>
    );

};

export default MyFunctionalComponent;
export {};
